<template>
  <div class="preview">
    <div class="content" ref="content">
      <div
        v-for="(cptData, i) in componentData"
        class="content-item"
        :class="
          !['footBar', 'homePageDialog'].includes(cptData.componentName) && cptData.selectStatus && 'componentSelect'
        "
        :key="i"
        @click="selectComponent(i)"
      >
        <comSearch :cptData="cptData" v-if="cptData.componentName == 'search'" :key="'search' + i"></comSearch>
        <comCarousel
          :cptData="cptData"
          v-else-if="cptData.componentName == 'carousel'"
          :key="'carousel' + i"
        ></comCarousel>
        <comClassify
          :cptData="cptData"
          v-else-if="cptData.componentName == 'classify'"
          :key="'classify' + i"
        ></comClassify>
        <comHomePageDialog
          :cptData="cptData"
          v-else-if="cptData.componentName == 'homePageDialog'"
          :key="'homePageDialog' + i"
        ></comHomePageDialog>
        <!-- <div v-else-if="cptData.componentName == 'seckill_1'" :key="'seckill_1' + i">
          <comSeckill_1 :cptData="cptData"></comSeckill_1>
          </div>
          <div v-else-if="cptData.componentName == 'seckill_2'" :key="'seckill_2' + i">
            <comSeckill_2 :cptData="cptData"></comSeckill_2>
          </div>
          <div v-else-if="cptData.componentName == 'seckill_3'" :key="'seckill_3' + i">
            <comSeckill_3 :cptData="cptData"></comSeckill_3>
        </div>-->
        <comWaterfall_1
          :cptData="cptData"
          v-else-if="cptData.componentName == 'waterfall_1'"
          :key="'waterfall_1' + i"
        ></comWaterfall_1>
        <comWaterfall_2
          :cptData="cptData"
          v-else-if="cptData.componentName == 'waterfall_2'"
          :key="'waterfall_2' + i"
        ></comWaterfall_2>
        <comWaterfall_3
          :cptData="cptData"
          v-else-if="cptData.componentName == 'waterfall_3'"
          :key="'waterfall_3' + i"
        ></comWaterfall_3>
        <comAreaAndClassify
          :cptData="cptData"
          v-else-if="cptData.componentName == 'areaAndClassify'"
          :key="'areaAndClassify' + i"
        ></comAreaAndClassify>
        <comFootBar
          :cptData="cptData"
          v-else-if="cptData.componentName == 'footBar'"
          :key="'footBar' + i"
        ></comFootBar>
        <comDiscover
          :cptData="cptData"
          v-else-if="cptData.componentName == 'discover'"
          :key="'discover' + i"
        ></comDiscover>
        <comCube_1
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_1'"
          :key="'cube_1' + i"
        ></comCube_1>
        <comCube_2
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_2'"
          :key="'cube_2' + i"
        ></comCube_2>
        <comCube_3
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_3'"
          :key="'cube_3' + i"
        ></comCube_3>
        <comCube_4
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_4'"
          :key="'cube_4' + i"
        ></comCube_4>
        <comCube_5
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_5'"
          :key="'cube_5' + i"
        ></comCube_5>
        <comCube_6
          :cptData="cptData"
          v-else-if="cptData.componentName == 'cube_6'"
          :key="'cube_6' + i"
        ></comCube_6>
        <comSeckill
          :cptData="cptData"
          v-else-if="cptData.componentName == 'seckill'"
          :key="'seckill' + i"
        ></comSeckill>

        <!-- 微页面组件 -->
        <tinyPageTitle
          :cptData="cptData"
          v-else-if="cptData.componentName == 'pageTitle'"
          :key="'pageTitle' + i"
        ></tinyPageTitle>
        <tinyPageText
          :cptData="cptData"
          v-else-if="cptData.componentName == 'textContent'"
          :key="'textContent' + i"
        ></tinyPageText>
        <tinyPageImage
          :cptData="cptData"
          v-else-if="cptData.componentName == 'imageUrl'"
          :key="'imageUrl' + i"
        ></tinyPageImage>
        <tinyPageProduct
          :cptData="cptData"
          v-else-if="cptData.componentName == 'productListComponent'"
          :key="'productListComponent' + i"
        ></tinyPageProduct>
      </div>
    </div>
  </div>
</template>

<script>
import comMixin from '@/component/adorn'
import tinyPageTitle from "../__com__/tiny-page/pageTitle.vue"
import tinyPageImage from "../__com__/tiny-page/imageUrl.vue"
import tinyPageProduct from "../__com__/tiny-page/productListComponent.vue"
import tinyPageText from "../__com__/tiny-page/textContent.vue"

export default {
  mixins: [comMixin],
  components: {tinyPageTitle,tinyPageImage, tinyPageProduct, tinyPageText},
  data() {
    return {}
  },
  props: {
    componentData: {
      type: Array,
      default: []
    }
  },
  computed: {},
  methods: {
    selectComponent(v) {
      this.$emit('selectComponent', v)
    }
  }
}
</script>

<style lang="less" scoped>
.componentSelect {
  border: 1px solid red !important;
}
::-webkit-scrollbar {
  width: 0;
  height: 11px;
  border-radius: 30px;
  background-color: #d9e0eb;
}
::-webkit-scrollbar-thumb {
  background-color: #dee1e6;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.preview {
  display: inline-block;
  height: 100%;
  margin: auto;
  padding: 60px 40px 60px;
  background-image: url("../../../asset/phone.png");
  background-size: 100% 100%;
  text-align: left;
  position: relative;

  .radius(10px);
  .content {
    height: 814px;
    width: 375px;
    // height: 600px;
    overflow: auto;
    padding: 5px 0 100px;
    .radius(10px);
    .content-item {
      border: 1px solid transparent;
      .radius(6px);
      &:hover {
        cursor: pointer;
        border: 1px dashed red;
      }
      // & > div {
      //   pointer-events: none;
      // }
    }
  }
}
</style>
